import styled, {css} from 'styled-components';

import {color, font} from '../../../../styles/variables';
import {md} from '../../../../styles/media';

export const wrapperCommonStyle = css`
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${md`
        width: 50%;
    `}
`;

export const Wrapper = styled.div`
    margin-bottom: 2rem;
`;

export const LeftWrapper = styled.div`
    background-color: ${color.lightBlue};
    ${wrapperCommonStyle};
`;

export const RightWrapper = styled.div`
    background-color: ${color.red};
    ${wrapperCommonStyle};
`;

export const UserNameText = styled.h3`
    margin: 0 0 1rem 0;
    color: ${color.black};
    font-weight: ${font.weight.bold};
    font-size: ${font.size.large};
    line-height: 1;
    font-family: ${font.type.serif};
    text-align: center;
`;

export const PharmacyNameText = styled.h6`
    margin: 0;
    color: ${color.black};
    font-size: ${font.size.medium};
    font-weight: ${font.weight.regular};
    line-height: 1;
    font-family: ${font.type.serif};
    text-align: center;
`;

export const PointTitle = styled.p`
    color: ${color.white};
    font-size: ${font.size.small};
    line-height: 1;
    margin: 0 0 0.5rem 0;
    text-align: center;
`;

export const PointText = styled.p`
    color: ${color.white};
    font-size: 4rem;
    line-height: 1;
    margin: 0;
    font-weight: ${font.weight.bold};
    font-style: ${font.style.italic};
    text-align: center;
    font-family: ${font.type.serif};
`;
