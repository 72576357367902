import styled from 'styled-components';

import Paragraph from '../../../components/Paragraph';
import {color, font} from '../../../../styles/variables';

export const HeadContainer = styled.div`
    width: 100%;
    padding: 0rem 1.125rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1px;
`;

export const HeadText = styled(Paragraph)`
    color: ${color.black};
    font-weight: ${font.weight.bold};
    font-family: ${font.type.serif};
`;
