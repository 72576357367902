import styled from 'styled-components';
import Icon from '../../../components/Icon';
import Paragraph from '../../../components/Paragraph';

import {smMax} from '../../../../styles/media';
import {color, font} from '../../../../styles/variables';

export const ItemContainer = styled.div`
    background-color: ${color.red};
    width: 100%;
    padding: 1.125rem;

    ${smMax`
		padding: 1.125rem 0.525rem;
	`};

    display: flex;
    justify-content: space-between;
    margin-bottom: 1px;
`;

export const HeadContainer = styled.div`
    width: 100%;
    padding: 0 1.125rem;
    display: flex;
    justify-content: space-between;

    ${smMax`
		padding: 0 0.125rem;
	`};

    margin-bottom: 1px;
`;

export const StyledIcon = styled(Icon)`
    margin-right: 0.3125rem;
`;

export const HeadText = styled(Paragraph)`
    color: ${color.black};
    font-weight: ${font.weight.bold};
    font-family: ${font.type.serif};
    width: 33%;
`;

export const ItemText = styled(Paragraph)`
    color: ${color.white};
    font-weight: ${font.weight.bold};
    margin: 0rem;
    text-align: left;
    width: 33%;
    justify-content: ${(props) => props.align};
    align-items: center;
    display: flex;
`;
