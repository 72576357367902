import styled from 'styled-components';

import Paragraph from '../../../../ui/components/Paragraph';
import {color, font} from '../../../../styles/variables';

export const SalesAgentImage = styled.img`
    border-radius: 5rem;
    width: 10rem;
    height: 10rem;
    overflow: hidden !important;
    margin-top: -5rem;
    position: relative;
`;

export const Placeholder = styled.span`
    position: relative;
    width: 10rem;
    height: 10rem;
    margin-top: -5rem;
    border-radius: 5rem;
    background-color: ${color.lightBlue};
    color: ${color.red};
    font-weight: ${font.weight.bold};
    font-size: ${font.size.xcessive};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Wrapper = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    width: 20rem;
    max-width: 100%;
    margin: 4rem auto 0 auto;
`;

export const Box = styled.div`
    background-color: ${color.snow};
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;

    .ap-link {
        margin-top: 1rem;
    }
`;

export const SalesAgentName = styled(Paragraph)`
    font-family: ${font.type.serif};
    font-weight: ${font.weight.bold};
`;
