import React from 'react';
import PropTypes from 'prop-types';

import {ItemContainer, ItemText} from './UserHighScoreItem.styles';

const UserHighScoreItem = ({userName, score}) => {
    return (
        <ItemContainer>
            <ItemText>{userName}</ItemText>
            <ItemText scoreText>{score}</ItemText>
        </ItemContainer>
    );
};

UserHighScoreItem.propTypes = {
    userName: PropTypes.string,
    score: PropTypes.number,
};

UserHighScoreItem.defaultProps = {
    userName: '',
    score: null,
};

export default UserHighScoreItem;
