import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import Layout from '../ui/layout/Layout';
import Headline from '../ui/components/Headline';
import Paragraph from '../ui/components/Paragraph';
import Header from '../ui/layout/Header';
import Link from '../ui/components/Link';
import TeamHeaderImage from '../assets/images/placeholder/hero/img-hero-deinteam.png';
import {Container, Section} from '../ui/layout/Grid';
import UserPoints from '../ui/domains/pharmacy/UserPoints';
import SalesAgent from '../ui/domains/pharmacy/SalesAgent';

import lang from '../lang/pages/team.lang';

import {
    selectUser,
    selectRoles as selectUserRoles,
    Roles,
} from '../model/profile';
import {
    selectPharmacyById,
    selectSalesAgent,
    selectCurrentPharmacyId,
    selectUserHighscore,
} from '../model/pharmacy';
import {loadCurrentWithColleagues} from '../model/pharmacy/actions';
import UserHighScore from '../ui/domains/pharmacy/Highscore/UserHighScore';
import PharmacyHighScore from '../ui/domains/pharmacy/Highscore/PharmacyHighScore';
import {routes} from '../model/navigation/routes';

export const Team = ({currentPharmacy, profile, salesAgent, userHighscore}) => {
    const isSalesAgentPharmacyServiceCenter =
        salesAgent && salesAgent.territory === 'ASC';
    return (
        <Layout>
            <Header img={TeamHeaderImage} alt={lang.headerAlt} paddingTop>
                <Headline type="h1">{lang.duUndDeinTeam}</Headline>
                <Paragraph>{lang.paragraph1}</Paragraph>
            </Header>
            <Section>
                <Container width="narrow" className="text-center">
                    <Headline type="h3">{lang.deinPunktestand}</Headline>
                    <Paragraph>{lang.paragraph2}</Paragraph>
                    <UserPoints
                        username={profile && profile.username}
                        points={profile && profile.points}
                        pharmacyName={currentPharmacy && currentPharmacy.name}
                    />
                    <Link showAsButton href={routes.punktehistorie}>
                        {lang.historyButton}
                    </Link>
                </Container>
            </Section>
            <Section theme="blue">
                <Container width="narrow" className="text-center">
                    <Headline type="h3">{lang.missDich}</Headline>
                    <Paragraph>{lang.paragraph3}</Paragraph>
                    <UserHighScore items={userHighscore} />
                </Container>
                <Container width="narrow">
                    <Headline type="h3" align="center">
                        {lang.imVergleich}
                    </Headline>
                    <PharmacyHighScore
                        name={currentPharmacy && currentPharmacy.name}
                        totalPoints={currentPharmacy && currentPharmacy.points}
                        ranking={currentPharmacy && currentPharmacy.ranking}
                    />
                </Container>
            </Section>
            <Section>
                <Container width="narrow" className="text-center">
                    <Headline type="h3">
                        {lang.aussendienstmitarbeiter}
                    </Headline>
                    <Paragraph>{lang.paragraph4}</Paragraph>
                </Container>
                <Container>
                    {salesAgent ? (
                        <SalesAgent
                            image={salesAgent.image}
                            fullName={
                                isSalesAgentPharmacyServiceCenter
                                    ? lang.ascData.fullName
                                    : `${salesAgent.firstName} ${salesAgent.lastName}`
                            }
                            type={
                                isSalesAgentPharmacyServiceCenter
                                    ? null
                                    : salesAgent.type
                            }
                            email={salesAgent.email}
                            phone={salesAgent.phone}
                        />
                    ) : null}
                </Container>
            </Section>
        </Layout>
    );
};

const ConnectedTeam = () => {
    const currentPharmacyId = useSelector(selectCurrentPharmacyId);
    const dispatch = useDispatch();
    useEffect(() => {
        if (currentPharmacyId) {
            dispatch(loadCurrentWithColleagues());
        }
    }, [dispatch, currentPharmacyId]);

    const userRoles = useSelector(selectUserRoles);
    const isTestUser = userRoles.includes(Roles.TEST_USER);
    const profile = useSelector(selectUser);
    const salesAgent = useSelector(selectSalesAgent);
    const currentPharmacy = useSelector((state) =>
        selectPharmacyById(state, currentPharmacyId)
    );
    const userHighscore = useSelector(selectUserHighscore);

    return (
        <Team
            profile={isTestUser ? lang.mockedDataForTestUser.profile : profile}
            currentPharmacy={
                isTestUser
                    ? lang.mockedDataForTestUser.currentPharmacy
                    : currentPharmacy
            }
            salesAgent={isTestUser ? null : salesAgent}
            userHighscore={
                isTestUser
                    ? lang.mockedDataForTestUser.userHighscore
                    : userHighscore
            }
        />
    );
};

Team.propTypes = {
    profile: PropTypes.shape({
        points: PropTypes.number,
        username: PropTypes.string,
    }),
    salesAgent: PropTypes.shape({
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        type: PropTypes.string,
        phone: PropTypes.string,
        image: PropTypes.string,
        territory: PropTypes.string,
    }),
    currentPharmacy: PropTypes.shape({
        name: PropTypes.string,
        points: PropTypes.number,
        ranking: PropTypes.number,
    }),
    userHighscore: PropTypes.array,
};

Team.defaultProps = {
    profile: {},
    currentPharmacy: {},
    salesAgent: {},
    userHighscore: [],
};

export default ConnectedTeam;
