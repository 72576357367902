import React from 'react';

export default {
    duUndDeinTeam: (
        <>
            <strong>Du und Dein Team –</strong> gemeinsam punkten!
        </>
    ),
    paragraph1:
        'In diesem Bereich kannst Du Deinen Punktestand einsehen sowie den Deiner Apotheke und Deiner Kollegen. Mit vereinten Kräften kommt ganz schön was zusammen, oder?',
    deinPunktestand: (
        <i>
            <strong>Dein Punktestand</strong> steht Dir
        </i>
    ),
    paragraph2:
        'Mit wie vielen Punkten Du Dich und Deine Apotheke schmücken kannst, erfährst Du hier.',
    historyButton: 'Zu Deiner Punktehistorie',
    missDich: (
        <>
            <strong>Sammle</strong>
            {` `}
            <i>mit deinen Kollegen</i>
        </>
    ),
    paragraph3:
        'Wie viele Punkte Deine Kollegen gesammelt haben und wie ihr gemeinsam die Rangliste erklimmt, kannst Du hier nachschauen.',
    imVergleich: (
        <>
            <strong>Deine Apotheke</strong>
            {` `}
            <i>im Vergleich</i>
        </>
    ),
    paragraph4:
        'Du hast eine Frage oder irgendetwas passt Dir nicht? Dein persönlicher Berater hilft Dir immer gerne weiter!',
    aussendienstmitarbeiter: (
        <>
            <i>
                Stilsichere Beratung{' '}
                <strong>von Deinem persönlichen Ansprechpartner</strong>{' '}
            </i>
        </>
    ),
    headerAlt: 'Header-Bild: PTA mit ihren Kollegen.',
    ascData: {
        fullName: 'Apotheken-Service-Center',
        type: 'OTC-Berater',
        email: 'info@pohl-boskamp.de',
        phone: '04826 59 0',
    },
    mockedDataForTestUser: {
        profile: {
            username: 'Annika',
            points: 300,
        },
        currentPharmacy: {
            name: 'Neue Apotheke',
            points: 950,
            ranking: 7,
        },
        userHighscore: [
            {
                id: 1,
                username: 'Annika',
                points: 300,
            },
            {
                id: 2,
                username: 'Susanna',
                points: 250,
            },
            {
                id: 3,
                username: 'Nina',
                points: 400,
            },
        ],
    },
};
