import React from 'react';
import PropTypes from 'prop-types';

import {
    SalesAgentImage,
    Placeholder,
    Wrapper,
    Box,
    SalesAgentName,
} from './styles';
import Paragraph from '../../../../ui/components/Paragraph';
import Link from '../../../../ui/components/Link';
import {makeUrl} from '../../../../model/backend-api';
import {getInitials} from '../../../../utils/text-processing-utils';

const SalesAgent = ({image, fullName, type, email, phone}) => {
    const initials = getInitials(fullName);
    return (
        <Wrapper>
            <Box>
                {image ? (
                    <SalesAgentImage src={makeUrl(image)} />
                ) : (
                    <Placeholder>{initials}</Placeholder>
                )}
                <SalesAgentName margin="1.5rem 0 0 0">
                    {fullName}
                </SalesAgentName>
                {type && <Paragraph margin="1rem 0 0 0">{type}</Paragraph>}
                <Link href={`tel:${phone}`}>{phone}</Link>
                <Link href={`mailto:${email}`}>{email}</Link>
            </Box>
        </Wrapper>
    );
};

SalesAgent.propTypes = {
    image: PropTypes.string,
    fullName: PropTypes.string.isRequired,
    type: PropTypes.string,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
};

export default SalesAgent;
