import React from 'react';
import PropTypes from 'prop-types';
import {HeadContainer, HeadText} from './UserHighScore.styles';

import UserHighScoreItem from './UserHighScoreItem';

const UserHighScore = ({items}) => {
    return (
        <>
            <HeadContainer>
                <HeadText>Username</HeadText>
                <HeadText>Punktezahl</HeadText>
            </HeadContainer>
            {items.map((item) => (
                <React.Fragment key={item.id}>
                    <UserHighScoreItem
                        userName={item.username}
                        score={item.points}
                    />
                </React.Fragment>
            ))}
        </>
    );
};

UserHighScore.propTypes = {
    items: PropTypes.array,
};

UserHighScore.defaultProps = {
    items: null,
};

export default UserHighScore;
