import React from 'react';
import PropTypes from 'prop-types';

import {
    ItemContainer,
    HeadContainer,
    HeadText,
    ItemText,
    StyledIcon,
} from './PharmacyHighScore.styles';
import {color} from '../../../../styles/variables';

const PharmacyHighScore = ({name, totalPoints}) => {
    return (
        <>
            <HeadContainer>
                <HeadText align="left">Apotheken&shy;name</HeadText>
                <HeadText align="right">Gesamt&shy;punkte</HeadText>
                {/* <HeadText align="right">Platz&shy;ierung</HeadText> */}
            </HeadContainer>
            <ItemContainer>
                <ItemText align="flex-start">{name}</ItemText>
                <ItemText align="flex-end">
                    <StyledIcon
                        name="starFilled"
                        color={color.white}
                        size="1.5rem"
                    />
                    {totalPoints}
                </ItemText>
                {/* <ItemText align="flex-end">
                    <StyledIcon
                        name="awards"
                        color={color.white}
                        size="1.5rem"
                    />
                    {ranking}
                </ItemText> */}
            </ItemContainer>
        </>
    );
};

PharmacyHighScore.propTypes = {
    name: PropTypes.string,
    totalPoints: PropTypes.number,
    ranking: PropTypes.number,
};

PharmacyHighScore.defaultProps = {
    name: '',
    totalPoints: 0,
    ranking: 0,
};

export default PharmacyHighScore;
