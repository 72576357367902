import React from 'react';
import PropTypes from 'prop-types';
import {Container, Row} from '../../../layout/Grid';

import {
    Wrapper,
    LeftWrapper,
    RightWrapper,
    UserNameText,
    PharmacyNameText,
    PointTitle,
    PointText,
} from './styles';

const UserPoints = ({username, pharmacyName, points}) => {
    return (
        <Wrapper>
            <Container>
                <Row>
                    <LeftWrapper>
                        <UserNameText>{username}</UserNameText>
                        <PharmacyNameText>{pharmacyName}</PharmacyNameText>
                    </LeftWrapper>
                    <RightWrapper>
                        <PointTitle>Deine Punkte</PointTitle>
                        <PointText>{points}</PointText>
                    </RightWrapper>
                </Row>
            </Container>
        </Wrapper>
    );
};

UserPoints.propTypes = {
    username: PropTypes.string,
    pharmacyName: PropTypes.string,
    points: PropTypes.number,
};

UserPoints.DefaultTypes = {
    username: '',
    pharmacyName: '',
    points: 0,
};

export default UserPoints;
